import React from 'react';

function Slides1(props) {
    return (
        <div>
            slide 1
        </div>
    );
}

export default Slides1;