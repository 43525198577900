const slides = [
    {
        id: 0,
        image: 'image1.jpg',
        text: 'First slide',
    },
    {
        id: 1,
        image: 'image2.jpg',
        text: 'Second slide',
    },
    {
        id: 2,
        image: 'image3.jpg',
        text: 'Third slide',
    },
];

export default slides;