import React from 'react';

function Slides3(props) {
    return (
        <div className="">
            slide 3
        </div>
    );
}

export default Slides3;