import React from 'react';

function Slides2(props) {
    return (
        <div className="">
            slide 2
        </div>
    );
}

export default Slides2;